<template>
  <div class="home">
    <div>
      <el-input placeholder="请输入元器件型号" class="input-with" v-model="goodsSn">
        <el-button type="success" slot="append" icon="el-icon-search" @click="onSearch"></el-button>
      </el-input>
    </div>
    <el-card class="card-with" v-loading="loading">
      <ul v-if="list.length > 0 && list !== null" v-infinite-scroll="loadMore" infinite-scroll-disabled="disabled">
        <li v-for="item in list" :key="item.goodsId" @click="handleItem(item.goodsId)">
          <div class="attribute">{{item.goodsSn}}</div>
          <div class="attribute">{{item.brandName}}</div>
          <div class="attribute">{{item.stock}}</div>
        </li>
      </ul>
      <p v-if="loadmore">加载中...</p>
      <p v-if="noMore">没有更多了</p>
<!--      <el-empty v-else :image-size="200"></el-empty>-->
    </el-card>
    <bottom-navigation></bottom-navigation>
  </div>
</template>
<script>
import BottomNavigation from "@/components/BottomNavigation";
import { pageStock } from '../../util/request';

export default {
  components: {BottomNavigation},
  data() {
    return {
      goodsSn:'',
      page: 1,
      current:1,
      count:0,
      list:[],
      loadmore:false,
      loading:true
    };
  },
  mounted() {
    this.onSearch()
  },
  computed:{
    noMore() {
      return this.list.length >= this.count;
    },
    disabled() {
      return this.loadmore || this.noMore;
    }
  },
  methods: {
    // 搜索
    onSearch(){
      this.list = []
      this.loading = true
      pageStock({
        goodsSn:this.goodsSn,
        page:1,
        pageSize: 20,
      }).then(res=>{
        this.list = res.data.data.records
        this.count = res.data.data.total
        this.loading = false
      })
    },

    // 滑动到底部 begin
    loadMore(){
      this.loadmore = true
      pageStock({
        goodsSn:this.goodsSn,
        page: this.current + 1,
        pageSize: 20,
      }).then(res => {
        this.current = res.data.data.current
        this.list = [...this.list , ...res.data.data.records]
        this.loadmore = false
      })
    },
    // 滑动到底部 end

    // 点击型号 跳转到详情页
    handleItem(goodsId){
      // this.$router.push({
      //   name:'Details',
      //   params:{
      //     id:goodsId
      //   }
      // })
      window.open(`https://mall.ichelps.com/detail/${goodsId}`,'_blank')
    },
  },
};

</script>
<style lang="less" scoped>
ul {
  list-style: none;
}
.input-with {
  width:600px;

  /deep/ .el-input-group__append:hover{
    background-color: #57aaff;
    color: #fff;
  }
}

.card-with {
  width: 1000px;
  height: 600px;
  margin: 0 auto;
  margin-top: 20px;
  overflow: auto;
}
ul li {
  line-height: 40px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  font-size: 20px;
}
ul li:hover {
  background-color:grey;
  color: #fff;
}

.attribute {
  width: 100%;
}

</style>
